import { Component, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';
import localeRO from '@angular/common/locales/ro';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PAGE } from '@ap-angular/shared/schema';
import { AbstractComponent } from '@ap-angular/shared/abstract';
import { ApplicationService } from '@ap-angular/service/application';
import { AppointmentService } from '@ap-angular/service/appointment';
import { AnalyticsService } from '@ap-angular/service/analytics';

@Component({
	selector: 'ap-root',
	templateUrl: './app.component.html',
})
export class AppComponent extends AbstractComponent implements OnInit {
	title = 'booking';

	constructor(
		private applicationService: ApplicationService,
		private appointmentService: AppointmentService,
		private analyticsService: AnalyticsService,
		private router: Router,
	) {
		super();
	}

	public ngOnInit(): void {
		registerLocaleData(localeRO);

		this.applicationService.initVersion();
		this.applicationService.initResize();
		this.analyticsService.init();

		super.addSubscription(combineLatest([this.appointmentService.error$()])
			.pipe(
				filter(([appointmentError]) => (!!appointmentError && !!appointmentError.status))
			)
			.subscribe(([appointmentError]) => {
				const ERROR = [appointmentError].find((e) => !!e && e.status);
				switch (ERROR.status) {
					case 400:
						if (ERROR.error.error === 'invalid_grant' && ERROR.error.error_description.indexOf('Invalid refresh token') > -1) {
							//this.userService.logout(false);
							window.location.reload();
						}
						break;
					case 401:
						//this.userService.logout(false);
						window.location.reload();
						break;
					case 426:
					case 404:
					case 408:
					case 502:
					case 0:
						this.router.navigateByUrl(PAGE.ERROR.path).then();
						break;
					default:
					// Do nothing
				}
			})
		);
	}
}
